module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"brl-website","path":"/preview","previews":true,"pages":[{"type":"Job_item","match":"/join/:uid","path":"/job-preview","component":"/builds/b-reel/b-reel-website-v4/src/templates/job.js"},{"type":"Project","match":"/work/:uid","path":"/project-preview","component":"/builds/b-reel/b-reel-website-v4/src/templates/project.js"},{"type":"Project","match":"/case-study/:uid","path":"/case-study-preview","component":"/builds/b-reel/b-reel-website-v4/src/templates/project-case-study.js"},{"type":"Log_item","match":"/log/:uid","path":"/log-preview","component":"/builds/b-reel/b-reel-website-v4/src/templates/post.js"}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B-Reel","start_url":"/","background_color":"#000000","theme_color":"#fff","display":"minimal-ui","icon":"src/images/b-reel-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-66363217-5","anonymize":true,"cookieName":"brl-website-gdpr-ga"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
