export const linkResolver = doc => {
  
  switch (doc.type) {
    case 'job_item':
      return `/join/${doc.uid}`;
    case 'project':
      return `/work/${doc.uid}`;
    case 'log_item':
      return `/log/${doc.uid}`;
    case 'homepage':
    case 'about':
    case 'work':
    case 'join':
      return `/${doc.type}`;
    case 'join_page':
      return `/join`;
    default:
      return '/';
  }
};
