/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import { linkResolver } from 'utils/linkResolver';

registerLinkResolver(linkResolver);


const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {}
}